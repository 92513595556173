export const WORDS = [
  'kisses',
  'moosie',
  'pototo',
  'lottle',
  'liuliu',
  'totpie',
  'slothy',
  'cuties',
  'sbubby',
  'cuddle',
  'cawwot',
  'noodle',
  'pandey',
  'teethy',
  'doodoo',
  'fododo',
  'schnog',
  'adorbs',
  'trashy',
  'wordlu',
]

// Use to shuffle list when adding new words
// const shuffle = (a: any[]) => a.sort(() => Math.random() - 0.5)
